.D6_SL_Navbar_Items {
    width: 100%;
    height: 100px;
    display: flex;
    justify-content: space-between;
    /* background-color: rgba(99,192,214,0.3); */
    background-color: white;
    color: black;
   position: fixed; 
    top: 0;
    z-index: 101;
    border-bottom-style: solid;
    border-bottom-color: dimgrey;
    border-bottom-width: 2px;

}
.icon-close-btn1{
    left: 184px;
    top: -27px;
}
.D6_SL_Navbar_Items1 {
    width: 100%;
    /* height: 70px; */
    /* margin-top: 100px; */
    display: flex;
    justify-content: space-between;
    /* background-color: rgb(99,192,214,0.3); */
    color: #ffffff;
   /* position: fixed;  */
    /* top: 0; */
    z-index: 101;
}
.gird_nav{
    justify-content: flex-end !important;
    padding-right: 37px;
}

.D6_SL_Navbar_Items1_Image {
    width: 200px;
    height: 80px;
    /* padding: 10px 0px; */
    margin-top: 5px;
    margin-left: 20px;
    text-decoration: none;
    color: white;
}
.multi_icons{
    padding: 27px;
    font-size: 20px;
    color: black !important;
}
.multi_icons:hover{
    color: white ;
    cursor: pointer;
}
.grid_multi{
    text-align: right;
}
.grid_cont{
    /* padding-top: 30px; */
    padding-left: 15px;
}
.D6_SL_Navbar_Link1{
    
    color:black !important;

    text-decoration: none;
    font-weight: 600;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
}

.D6_SL_Navbar_Link2 {
    
    text-decoration: solid;
    color: #52B9D1;

    text-decoration-color: black;
    font-weight: 600;
    cursor: pointer;
    padding-left: 10px;
    padding-right: 10px;

}
.filly_icon{
   cursor: pointer;
}

@media (max-width:500px) {
    .Grid_MC{
        justify-content: center;
      }
      .D6_SL_Navbar_Items {
          height: auto !important;
      }
      .Grid_TC{
          justify-content: center;
      }
      .D6_SL_Navbar_Items1 {
        padding-bottom:10px !important;
    }
    .grid_cont{
        /* padding-top: 30px; */
        padding-left: 0px;
    }
    .grid_multi{
        text-align: center;
    }
    .gird_nav{
        justify-content: center !important;
        padding-right: 0px;
    }
}